import payload_plugin_qLmFnukI99 from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_UzIc2qvBnC from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/mp-landing-pages/mp-landing-pages/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/home/runner/work/mp-landing-pages/mp-landing-pages/.nuxt/floating-vue.mjs";
import plugin_client_5j40ijIZYu from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import siteConfig_izaWKZ8rEu from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import slideovers_X7whXyrIWR from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_ghbUAjaD3n from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_AUP22rrBAc from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _00_api_5pPcUwflGR from "/home/runner/work/mp-landing-pages/mp-landing-pages/plugins/00.api.js";
import _01_pinia_Dz0JCb7uOE from "/home/runner/work/mp-landing-pages/mp-landing-pages/plugins/01.pinia.js";
import _01_vue_mq_Slb6oz87om from "/home/runner/work/mp-landing-pages/mp-landing-pages/plugins/01.vue-mq.js";
import _02_mq_aI4m4QurgI from "/home/runner/work/mp-landing-pages/mp-landing-pages/plugins/02.mq.js";
import _100_vue_final_modal_G18bKUP1iT from "/home/runner/work/mp-landing-pages/mp-landing-pages/plugins/100.vue-final-modal.ts";
import _101_mixins_lcD6FeXk5x from "/home/runner/work/mp-landing-pages/mp-landing-pages/plugins/101.mixins.js";
import _106_toast_5emqTauiqZ from "/home/runner/work/mp-landing-pages/mp-landing-pages/plugins/106.toast.js";
import _108_clipboard_client_jV2uK4J4vy from "/home/runner/work/mp-landing-pages/mp-landing-pages/plugins/108.clipboard.client.js";
import compress_MZg8qOuGHv from "/home/runner/work/mp-landing-pages/mp-landing-pages/plugins/compress.js";
import events_MUQHEbFHmJ from "/home/runner/work/mp-landing-pages/mp-landing-pages/plugins/events.js";
import posthog_client_o9IEgU9LwG from "/home/runner/work/mp-landing-pages/mp-landing-pages/plugins/posthog.client.js";
import plugin_auto_pageviews_client_x3Y8XIaaPY from "/home/runner/work/mp-landing-pages/mp-landing-pages/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  cross_origin_prefetch_client_UzIc2qvBnC,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  floating_vue_EIcJ7xiw0h,
  plugin_client_5j40ijIZYu,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  plugin_ghbUAjaD3n,
  plugin_AUP22rrBAc,
  _00_api_5pPcUwflGR,
  _01_pinia_Dz0JCb7uOE,
  _01_vue_mq_Slb6oz87om,
  _02_mq_aI4m4QurgI,
  _100_vue_final_modal_G18bKUP1iT,
  _101_mixins_lcD6FeXk5x,
  _106_toast_5emqTauiqZ,
  _108_clipboard_client_jV2uK4J4vy,
  compress_MZg8qOuGHv,
  events_MUQHEbFHmJ,
  posthog_client_o9IEgU9LwG,
  plugin_auto_pageviews_client_x3Y8XIaaPY
]